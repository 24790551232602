import {API} from "aws-amplify";
import '../Stylesheets/Professors.css';
import "../Stylesheets/Students.css";
import Paper from "@material-ui/core/Paper";
import React, {useState, useEffect} from "react";
import MaterialTable, {MTableToolbar} from "material-table";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import ModalChooseProfessor from "../../components/Modals/ModalChooseProfessor";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {LOCALIZATION_GLOBAL_ES} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {
    openAlert,
    K_ALERT,
    calculateCurrentCuatrimestreYYYYCC
} from "../../components/CommonUtilities/UtilityFunctions";
import {TOPICS_COLUMN_DEFINITION} from "../../components/Material-TableCustomization/ColumnsDefinition/TopicsColumnDefinition";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, NativeSelect} from "@material-ui/core";

export default function Topics(props) {
    const [topics, setTopics] = useState([]);
    const [professors, setProfessors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cycle, setCycle] = useState(calculateCurrentCuatrimestreYYYYCC());
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const loadTopicsApi = (pathCycle) => {
        return API.get('misServiciosEscolares', `/topics?cycle=${pathCycle}`);
    };

    const loadProfessorsApi = () => {
        return API.get('misServiciosEscolares', "/professors");
    };

    const loadTopics = async (updatedCycle) => {
        setIsLoading(true);
        try {
            const response = await loadTopicsApi(updatedCycle ? updatedCycle : cycle);
            setTopics(response);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handleChangeOnAlert = (status) => {
        setAlertStatus(status);
    }

    const callback = (updateInProgress, error) => {
        if (updateInProgress) {
            setIsLoading(true);
        } else {
            loadTopics(cycle, true).finally(() => {
                if (!error) {
                    openAlert(setAlertStatus, K_ALERT.MESSAGE.TOPICS.TOPICS_UPDATE_PROFESSOR, K_ALERT.SEVERITY.SUCCESS);
                } else {
                    openAlert(setAlertStatus, K_ALERT.MESSAGE.ERROR, K_ALERT.SEVERITY.ERROR);
                }
            });
        }
    };

    const handleUpdateCycle = (e) => {
        const updatedCycle = e.target.value;
        if (updatedCycle) {
            setCycle(updatedCycle);
            loadTopics(updatedCycle).finally(() => {
                openAlert(setAlertStatus, K_ALERT.MESSAGE.TOPICS.TOPICS_UPDATE_PROFESSOR, K_ALERT.SEVERITY.SUCCESS);
            });
        }
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }

        loadTopics();
        loadProfessorsApi()
            .then(response => setProfessors(response))
            .catch(err => console.log(err));

    }, [props.isAuthenticated]);

    const renderTopics = () => {
        return (
            <div className="Topics">
                <MaterialTable
                    columns={TOPICS_COLUMN_DEFINITION}
                    data={topics}
                    title="Asignar Profesores UVT"
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION_GLOBAL_ES}
                    options={{
                        search: true,
                        pageSize: 20,
                        filtering: true
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <div style={{padding: '0px 10px'}}>
                                    <FormControl style={{width: '200px'}}>
                                        <InputLabel htmlFor="get-current-cycle">Ciclo Escolar</InputLabel>
                                        <NativeSelect
                                            id="get-current-cycle"
                                            value={cycle}
                                            onChange={handleUpdateCycle}
                                        >
                                            <option value="2020-03">2020-03</option>
                                            <option value="2021-01">2021-01</option>
                                            <option value="2021-02">2021-02</option>
                                            <option value="2021-03">2021-03</option>
                                            <option value="2022-01">2022-01</option>
                                            <option value="2022-02">2022-02</option>
                                            <option value="2022-03">2022-03</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                            </div>
                        ),
                    }}
                />
            </div>
        );
    }

    return (
        <div className="Professors">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            <CustomizedBackdrop open={isLoading}/>
            {props.isAuthenticated ? renderTopics() : renderLander()}
        </div>
    );
};