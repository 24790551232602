import React, {useState} from "react";
import {useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {API} from "aws-amplify";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {LOCALIZATION_GLOBAL_ES} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import MaterialTable from "material-table";
import {PRIVILEGES_COLUMN_DEFINITION} from "../../components/Material-TableCustomization/ColumnsDefinition/PrivilegesColumnDefinition";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Privileges(props) {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadPrivilegesApi = () => {
        return API.get("misServiciosEscolares", "/privileges");
    }

    const updatePrivilegesApi = (privilegesToBeUpdated) => {
        return API.put("misServiciosEscolares", "/privileges/", {
            body: privilegesToBeUpdated
        });
    };

    const loadPrivileges = async () => {
        setIsLoading(true);
        try {
            const response = await loadPrivilegesApi();
            setUsers(response);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadPrivileges();
    }, [props.isAuthenticated, props.username]);

    const handleOnRowUpdate = async (newData, oldData, resolve, reject) => {
        if (newData === oldData) {
            resolve();
        } else {
            try {
                let response = await updatePrivilegesApi(newData);
                if (response?.userId) {
                    const index = users.findIndex(o => o.userId === response.userId);
                    setUsers([
                        ...users.slice(0, index),
                        newData,
                        ...users.slice(index + 1)
                    ]);
                }
                resolve();
            } catch (e) {
                alert('El grupo no fue actualizado, por favor intenta de nuevo');
                resolve();
            }
        }
    };

    function renderPrivileges() {
        return (
            <div>
                <MaterialTable
                    columns={PRIVILEGES_COLUMN_DEFINITION(props)}
                    data={users}
                    title="Privilegios UVT"
                    components={REMOVE_DROP_SHADOW}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION_GLOBAL_ES}
                    options={{
                        search: true,
                        filtering: true,
                        pageSize: 20,
                    }}
                    editable={{
                        onBulkUpdate: changes => new Promise((resolve, reject) => {
                            resolve();
                        }),
                        onRowUpdate: (newData) => new Promise((resolve, reject) => {
                            handleOnRowUpdate(newData, resolve, reject);
                        }),
                    }}
                />
            </div>
        );}

    return (
        <div className="Groups">
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            {props.isAuthenticated ? renderPrivileges() : renderLander()}
        </div>
    );
}