import {API} from "aws-amplify";
import MaterialTable from "material-table";
import GroupIcon from '@material-ui/icons/Group';
import React, {forwardRef, useEffect, useState} from 'react';
import ModalChooseGroup from "../../Modals/ModalChooseGroup";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {LOCALIZATION} from "../../Material-TableCustomization/Localization/LocalizationES";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {STUDENTS_GRADES_TABLE_COLUMN_DEFINITION} from "../../Material-TableCustomization/ColumnsDefinition/StudentGradesTableColumnDefinition";
import {red} from "@material-ui/core/colors";

export default function StudentGradesTable(props) {
    const [grades, setGrades] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [openChooseGroupModal, setOpenChooseGroupModal] = useState(false);
    const tableIcons = {
        RemoveIcon: forwardRef((props, ref) => <RemoveCircleOutlineIcon color="secondary" {...props} ref={ref}/>),
        GroupIcon: forwardRef((props, ref) => <GroupIcon color="secondary" {...props} ref={ref}/>)
    };

    const updateTopicApi = (payload) => {
        return API.put("misServiciosEscolares", `/topics?type=NOTINGROUPSTUDENTSUPDATE?action=REMOVE`, {
            body: payload
        });
    };

    useEffect(() => {
        setGrades(props.grades);
        if (props.grades) {
            setIsLoading(false);
        }
    }, [props.grades]);

    const updateStudentGradeApi = (updatedStudentGrade, action) => {
        const payload = {
            studentUuid: props.student.studentUuid,
            topicId: updatedStudentGrade.topicId,
            grades: updatedStudentGrade
        };
        const actionParam = action ? action : "ADD";
        return API.put("misServiciosEscolares", `/grades?type=single&action=${actionParam}`, {
            body: payload
        });
    }

    const handleOnRowDelete = async (data)  => {
        // TODO: Do it when it includes the groupId on the grades table
        // try {
        //     const payload = {};
        //     let responseUpdate = await updateTopicApi(payload);
        // } catch (e) {
        //     alert("No se pudo completar su transacción");
        // }
    }

    // TODO: Refactor all the onRowUpdate to this format
    const handleOnRowUpdate = async (updatedStudent, resolve, reject) => {
        try {
            let responseUpdate = await updateStudentGradeApi(updatedStudent);
            let index = grades.findIndex(o => o.topicId === responseUpdate.topicId);
            setGrades([
                ...grades.slice(0, index),
                responseUpdate,
                ...grades.slice(index+1)
            ]);
        } catch (e) {
            alert('Por favor intente de nuevo');
            reject();
        } finally {
            resolve();
        }
    }

    function handleOnRowAdd(newItem) {
        let index = grades.findIndex(o => o.topicId === newItem.topicId);
        setGrades([
            ...grades.slice(0, index),
            {...grades[index], externalGroupId: newItem.externalGroupId},
            ...grades.slice(index + 1)
        ]);
    }

    return (
        <div>
            <ModalChooseGroup openChooseGroupModal={openChooseGroupModal} callbackClose={setOpenChooseGroupModal}
                              setIsLoading={setIsLoading}
                              selectedTopic={selectedRow} student={props.student} callbackResponse={handleOnRowAdd}/>
            <MaterialTable
                columns={STUDENTS_GRADES_TABLE_COLUMN_DEFINITION(props)}
                data={grades}
                title="Calificaciones"
                isLoading={isLoading}
                components={REMOVE_DROP_SHADOW}
                icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                localization={LOCALIZATION('materia', 'materias')}
                options={{
                    paging: false,
                    rowStyle: rowData => {
                        return {backgroundColor: rowData.failed ? red[100] : '#FFF'};
                    }
                }}
                // TODO: Change all the updates to this format
                editable={{
                    onRowUpdate: (newData) => new Promise((resolve, reject) => {
                        handleOnRowUpdate(newData, resolve, reject);
                    }),
                }}
                actions={[
                    {
                        icon: tableIcons.GroupIcon,
                        onClick: (event, rowData) => {
                            console.log(rowData);
                            setSelectedRow(rowData);
                            setOpenChooseGroupModal(true);
                        }
                    },
                    {
                        icon: tableIcons.RemoveIcon,
                        onClick: async (event, rowData) => {
                            if (window.confirm("Esta materia será llevada con su grupo asignado. Desea continuar")) {
                                handleOnRowDelete(rowData);
                            }
                        }
                    }
                ]}
            />
        </div>
    );
};