import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export function GROUP_DETAILS_TABLE_SUBJECT_DEFINITION_PROPS(props) {
    return [
        {
            title: 'Clave', field: 'topicId', editable: 'never',
            render: rowData =>
                <Button color='secondary'
                        onClick={() => props.history.push(`/topics/${rowData.topicId}?groupId=${props.group.groupId}`)}
                        component={Link}>
                    {rowData.topicId}
                </Button>
        },
        {title: 'Materia', field: 'topicName', editable: 'never',},
        {title: 'Cuatrimestre', field: 'topicCuatrimestre', editable: 'never',},
        {title: 'Ciclo', field: 'startingCycle', editable: 'never',},
        {title: 'Módulo', field: 'module', lookup:
                {
                    "MOD1": "Módulo 1",
                    "MOD2": "Módulo 2",
                    "MOD3": "Módulo 3",
                }},
        {title: 'Profesor Int', field: 'topicProfessorInternal', editable: 'never',},
    ];
}