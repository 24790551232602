import React from "react";

export function MAJORS_COLUMN_DEFINITION_FAST(props) {
    return [
        {
            title: 'Clave',
            field: 'topicId',
            editable: 'never',
            width: '7%'
        },
        {
            title: 'Cuatrimestre',
            field: 'topicCuatrimestre',
            width: '7%',
            lookup: {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "4 OPTATIVA 1": "4 OPTATIVA 1",
                "4 OPTATIVA 2": "4 OPTATIVA 2",
                "4 OPTATIVA 3": "4 OPTATIVA 3",
                "4 OPTATIVA 4": "4 OPTATIVA 4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10",
            }

        },
        {
            title: 'Nombre',
            field: 'topicName',
        },
    ];
}