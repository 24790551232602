import React from "react";

export const EMAILS_COLUMN_DEFINITION = [
    {
        title: 'ID Usuario',
        field: 'username'
    },
    {
        title: 'Email',
        field: 'email',
    },
    {
        title: 'Email Verificado',
        field: 'email_verified',
        lookup:
            {
                true: "Verificado",
                false: "Pendiente",
            }
    },
    {
        title: 'Rol',
        field: 'role',
        lookup:
            {
                "STUDENT": "Estudiante",
                "PROFESSOR": "Professor",
                "ADMINISTRATIVO": "Administrativo",
            }
    },
];