import "./App.css";
import config from "./config";
import Routes from "./Routes";
import {Auth} from "aws-amplify";
import {withRouter} from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import React, {useEffect, useState} from "react";
import {LinkContainer} from "react-router-bootstrap";
import {Form, Nav, Navbar, NavDropdown} from "react-bootstrap";

function App(props) {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [userAttributes, setUserAttributes] = useState({});
    const [username, setUsername] = useState('');

    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
            const response = await Auth.currentUserInfo();
            setUserAttributes(response.attributes);
            setUsername(response.username);
        } catch (e) {
        }
        setIsAuthenticating(false);
    }

    async function handleLogout() {
        await Auth.signOut();
        userHasAuthenticated(false);
        // TODO: After push, it's an entire load, not only history.push
        props.history.push("/login");
    }

    return (
        !isAuthenticating &&
        <div className="App container">
            <Navbar bg="translucent" expand="lg">
                <Navbar.Brand href="/">
                    <Tooltip title={config.env}>
                        <span>Admin UVT - {config.env}</span>
                    </Tooltip>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown title="Estudiantes" id="basic-nav-dropdown">
                            <NavDropdown.Item
                                onClick={() => props.history.push("/students-light")}>Información
                                Básica</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => props.history.push("/students")}>Información Detallada</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => props.history.push("/students-documents")}>Documentos</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => props.history.push("/students-prints")}>Formatería</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Admin" id="basic-nav-dropdown">
                            {/*<NavDropdown.Item onClick={() => props.history.push("/roles")}>Roles</NavDropdown.Item>*/}
                            <NavDropdown.Item
                                onClick={() => props.history.push("/privileges")}>
                                Privilegios
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => props.history.push("/majors")}>
                                Administrar materias
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => props.history.push("/emails")}>
                                Mandar emails
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                disabled={true}
                                onClick={() => props.history.push("/statistics")}>
                                Estadísticas
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={() => props.history.push("/professors")}>Profesores</Nav.Link>
                        <Nav.Link onClick={() => props.history.push("/groups")}>Grupos</Nav.Link>
                        <Nav.Link onClick={() => props.history.push("/registrations")}>Registros</Nav.Link>
                    </Nav>
                    <Form inline>
                        <Navbar.Collapse>
                            <Nav className="ml-auto">
                                {isAuthenticated
                                    ? <Nav.Link onClick={handleLogout}>
                                        <Tooltip title={userAttributes.email || ""}>
                                            <span>Cerrar Sesión</span>
                                        </Tooltip>
                                    </Nav.Link>
                                    : <>
                                        <LinkContainer to="/signup">
                                            <Nav.Link>Crear cuenta</Nav.Link>
                                        </LinkContainer>
                                        <LinkContainer to="/login">
                                            <Nav.Link>Entrar</Nav.Link>
                                        </LinkContainer>
                                    </>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
            <Routes appProps={{isAuthenticated, userHasAuthenticated, userAttributes, username}}/>
        </div>
    );
}

export default withRouter(App);