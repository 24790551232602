import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StudentGradesTable from "./StudentGradesTable";
import StudentDocumentsTable from "./StudentDocumentsTable";
import Registration from "../../../containers/Containers/Registration";
import {REGISTRATION_COMPONENT_TYPE} from "../../../libs/Constants";
import StudentPayments from "./StudentPayments";
import StudentDetailsNotes from "./StudentDetailsNotes";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


const StudentDetailsTabsContainer = props => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /**
     * No need to validate if the user has privileges, this validation should only happen for extraordinarios
     */
    // const validateAccessToExtraordinario = () => {
    //     if (props.username) {
    //         const withAccess = [
    //             "302caf93-a442-442f-8bd6-7a6da118b109", // aramis002@gmail.com
    //             "74f4576e-ca86-427c-ae19-1f610b4d0809", // camanan8@hotmail.com
    //             "a43f7995-8aa5-403f-b5b0-4025ce592374", // capturauvt@gmail.com
    //         ];
    //         return !(withAccess.includes(props.username));
    //     }
    //     return true;
    // };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Estudiantes">
                    <Tab label="Detalles" {...a11yProps(0)} />
                    <Tab label="Calificaciones" {...a11yProps(1)}/>
                    <Tab label="Documentos" {...a11yProps(2)} />
                    <Tab label="Tesoreria" {...a11yProps(3)} />
                    <Tab label="Notas" {...a11yProps(4)} />
                    {/*<Tab label="Certificados" {...a11yProps(2)} />*/}
                    {/*<Tab label="Extraordinarios" {...a11yProps(4)} />*/}
                    {/*<Tab label="Becas" {...a11yProps(5)} />*/}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Registration student={props.student} type={REGISTRATION_COMPONENT_TYPE.UPDATE_USER}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <StudentGradesTable grades={props.grades} student={props.student} history={props.history}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <StudentDocumentsTable
                    studentUuid={props.student?.studentUuid}
                    documents={props.student?.documents}
                />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <StudentPayments studentUuid={props.student?.studentUuid}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <StudentDetailsNotes studentUuid={props.student?.studentUuid}/>
            </TabPanel>
            {/*<TabPanel value={value} index={2}>*/}
            {/*    <StudentCertificates studentUuid={props.student?.studentUuid}*/}
            {/*                         kardexShouldBeDisabled={false}/>*/}
            {/*</TabPanel>*/}

            {/*<TabPanel value={value} index={4}>*/}
            {/*    <StudentExtraordinarioTable grades={props.grades}/>*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={5}>*/}
            {/*    <StudentScholarshipsTable studentId={props.student?.studentId}/>*/}
            {/*</TabPanel>*/}

        </div>
    );
};

export default StudentDetailsTabsContainer;