import {useEffect} from "react";
import {API} from "aws-amplify";
import React, {useState} from "react";
import MaterialTable from "material-table";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {LOCALIZATION_GLOBAL_ES} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ROLES_COLUMN_DEFINITION} from "../../components/Material-TableCustomization/ColumnsDefinition/RolesColumnDefinition";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Roles(props) {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadGroupsApi = () => {
        return API.get('misServiciosEscolares', "/privileges");
    };

    const loadPrivileges = async () => {
        setIsLoading(true);
        try {
            const response = await loadGroupsApi();
            setUsers(response);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const updatePrivilegesApi = (topicToBeUpdated) => {
        return API.put("misServiciosEscolares", `/topics/`, {
            body: topicToBeUpdated
        });
    };

    const handleOnRowUpdate = async (newData, oldData, resolve, reject) => {
        if (newData === oldData) {
            resolve();
        } else {
            resolve();
            // try {
            //     const payload = {topicId: `${newData.topicId}#${newData.groupId}`, module: newData.module};
            //     let response = await updatePrivilegesApi(payload);
            //     if (response?.topicId) {
            //         const index = topics.findIndex(o => o.topicId === response.topicId.split('#')[0]);
            //         setTopics([
            //             ...topics.slice(0, index),
            //             {...topics[index], module: response.module},
            //             ...topics.slice(index + 1)
            //         ])
            //     }
            //     resolve();
            // } catch (e) {
            //     alert('El grupo no fue actualizado, por favor intenta de nuevo');
            //     reject();
            // }
        }
    };

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadPrivileges();
    }, [props.isAuthenticated, props.username]);

    function renderPrivileges() {
        return (
            <div>
                <MaterialTable
                    columns={ROLES_COLUMN_DEFINITION(props.username, loadPrivileges)}
                    data={users}
                    title="Roles"
                    components={REMOVE_DROP_SHADOW}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION_GLOBAL_ES}
                    options={{
                        search: true,
                        filtering: true,
                        pageSize: 20,
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            handleOnRowUpdate(newData, oldData, resolve, reject);
                        }),
                    }}
                />
            </div>
        );
    }

    return (
        <div className="Groups">
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            {props.isAuthenticated ? renderPrivileges() : renderLander()}
        </div>
    );
}