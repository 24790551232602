import React from "react";
import "../Stylesheets/Home.css";

export default function Home(props) {
    return (
        <div className="Home">
            <div className="lander">
                <h1>Bienvenidos al Portal Administrativo</h1>
                <h2>No hay anuncios por el momento</h2>
            </div>
        </div>
    );
};