import {API} from "aws-amplify";
import {Checkbox, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import ButtonMaterialUIProgress from "../../CommonUtilities/ButtonMaterialUIProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    FormControl: {
        width: 550,
    },
}));

export default function StudentDocumentsTable(props) {
    const classes = useStyles();
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDocuments(props.documents);
    }, []);

    const handleCheckboxChange = (event, index) => {
        setDocuments([
            ...documents.slice(0, index),
            {
                ...documents[index],
                delivered: event.target.checked,
            },
            ...documents.slice(index + 1)
        ]);
    };

    const handleTextFieldChange = (event, index) => {
        setDocuments([
            ...documents.slice(0, index),
            {
                ...documents[index],
                value: event.target.value,
            },
            ...documents.slice(index + 1)
        ]);
        console.log(documents);
    };

    const updateStudentApi = (updatedStudent) => {
        return API.put("misServiciosEscolares", "/students", {
            body: updatedStudent
        });
    }

    const updateDocuments = async () => {
        try {
            setLoading(true);
            const payload = {studentUuid: props.studentUuid, documents: documents};
            await updateStudentApi(payload);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    return (
        <span>
            <ButtonMaterialUIProgress
                text="Guardar Información"
                color='secondary'
                variant='contained'
                onClick={updateDocuments} loading={loading}
            />
            {documents.map((document, index) => {
                if (document?.type && document?.type === 'TextField') {
                    return <TextField
                        id="standard-multiline-static"
                        label="Observaciones"
                        multiline
                        variant="outlined"
                        rows={4}
                        onChange={e => handleTextFieldChange(e, index)}
                        placeholder="Observaciones"
                        className={classes.FormControl}
                        value={document.value}
                    />;
                } else {
                    return <div>
                        <>
                            <Checkbox
                                checked={document.delivered}
                                onChange={e => handleCheckboxChange(e, index)}
                                inputProps={{'aria-label': 'primary checkbox'}}
                            />
                        </>
                        <>{document.description}</>
                    </div>;

                }
            })}

        </span>
    );
};