import {
    LOCALIZATION,
} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {
    STUDENTS_COLUMN_DEFINITION
} from "../../components/Material-TableCustomization/ColumnsDefinition/StudentsColumnDefinition";
import {API} from "aws-amplify";
import "../Stylesheets/Students.css";
import React, {useState, useEffect} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import MaterialTable, {MTableToolbar} from "material-table";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModalNewStudent from "../../components/Modals/ModalNewStudent";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import {K_ALERT, openAlert} from "../../components/CommonUtilities/UtilityFunctions";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";


export default function Students(props) {
    const [students, setStudents] = useState([]);
    const [columns, setColumns] = useState({
        name: {enabled: true, label: 'Nombre'},
        studentId: {enabled: true, label: 'Matrícula'},
        major: {enabled: true, label: 'Carrera'},
        email: {enabled: true, label: 'Email'},
        phone: {enabled: true, label: 'Teléfono'},
        notes: {enabled: true, label: 'Notas'},
        mode: {enabled: true, label: 'Modalidad'},
        curp: {enabled: true, label: 'CURP'},
        active: {enabled: true, label: 'Activo'},
        cuatrimestre: {enabled: true, label: 'Cuatrimestre'},
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isBackdrop, setIsBackdrop] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const loadStudentsApi = () => {
        return API.get('misServiciosEscolares', "/students");
    };

    const deleteStudentApi = (targetUser) => {
        return API.del("misServiciosEscolares", `/students/${targetUser.studentUuid}`);
    };

    const updateStudentApi = (updatedStudent) => {
        return API.put("misServiciosEscolares", "/students", {
            body: updatedStudent
        });
    }

    const handleCreateStudentComponentsAsync = (isBackdrop, studentCreated) => {
        setIsBackdrop(isBackdrop);
        if (studentCreated) {
            if (studentCreated !== 'PENDING') {
                updateStudentsAfterSuccessEdit();
            }
        } else {
            openAlert(setAlertStatus);
        }
    }

    const loadStudents = async () => {
        try {
            const students = await loadStudentsApi();
            setStudents(students);
            setIsLoading(false);
            setIsBackdrop(false);
        } catch (e) {
            alert(e);
            setIsBackdrop(false);
        }
    };

    const updateStudentsAfterSuccessDelete = deletedStudent => {
        setStudents(students.filter(student => student.studentId !== deletedStudent.studentId));
        openAlert(setAlertStatus, K_ALERT.MESSAGE.STUDENTS.STUDENT_DELETE, K_ALERT.SEVERITY.SUCCESS)
    };

    const updateStudentsAfterSuccessEdit = () => {
        loadStudents().finally(() => setIsBackdrop(false));
        openAlert(setAlertStatus, K_ALERT.MESSAGE.STUDENTS.STUDENT_UPDATE, K_ALERT.SEVERITY.SUCCESS)
    };

    const handleChangeOnAlert = shouldPresentAlert => {
        setAlertStatus(shouldPresentAlert);
    }

    const updateStudentsAfterSuccessCreate = () => {
        loadStudents().finally(() => setIsBackdrop(false));
        openAlert(setAlertStatus, K_ALERT.MESSAGE.STUDENTS.STUDENT_CREATE, K_ALERT.SEVERITY.SUCCESS)
    };

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadStudents().finally(setIsLoading(false));
    }, [props.isAuthenticated]);

    const updateColumns = (column, checked) => {
        setColumns({
            ...columns,
            [column]: {enabled: checked, label: columns[column]['label']}
        });
    };

    function renderStudents() {
        // TODO: Update localization and put "Filtrar estudiantes"
        return (
            <div className="Students">

                <MaterialTable
                    columns={STUDENTS_COLUMN_DEFINITION(columns)}
                    data={students}
                    title="Estudiantes UVT"
                    isLoading={isLoading}
                    options={{
                        search: true,
                        filtering: true,
                        pageSize: 20,
                    }}
                    components={{
                        ...REMOVE_DROP_SHADOW,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <div style={{padding: '0px 10px'}}>
                                    {Object.keys(columns).map((column, index) => <FormControlLabel
                                        control={<Checkbox checked={columns[column]['enabled']}
                                                           onChange={e => updateColumns(column, e.target.checked)}
                                        />}
                                        label={columns[column]['label']}
                                    />)}
                                </div>
                            </div>
                        ),
                    }}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('estudiante', 'estudiantes')}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            updateStudentApi(newData).then(() => {
                                updateStudentsAfterSuccessEdit();
                            }).catch(() =>
                                setAlertStatus({open: true, message: "Por favor intenta de nuevo", severity: "error"})
                            ),
                        onRowDelete: selectedStudent =>
                            deleteStudentApi(selectedStudent).then(
                                updateStudentsAfterSuccessDelete(selectedStudent)
                            ).catch(() =>
                                setAlertStatus({open: true, message: "Por favor intenta de nuevo", severity: "error"})
                            ),
                    }}
                />
                <ModalNewStudent onCreate={updateStudentsAfterSuccessCreate}
                                 callback={handleCreateStudentComponentsAsync}/>
            </div>
        );
    }

    return (
        <div className="Students">
            <CustomizedBackdrop open={isBackdrop}/>
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            {props.isAuthenticated ? renderStudents() : renderLander()}
        </div>
    );
};


