import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/Containers/Home";
import Login from "./containers/Containers/Login";
import Signup from "./containers/Containers/Signup";
import Roles from "./containers/Containers/Roles";
import Groups from "./containers/Containers/Groups";
import Topics from "./containers/Containers/Topics";
import Emails from "./containers/Containers/Emails";
import AppliedRoute from "./components/AppliedRoute";
import NotFound from "./containers/Containers/NotFound";
import Students from "./containers/Containers/Students";
import StudentsLight from "./containers/Containers/StudentsLight";
import Prospects from "./containers/Containers/Prospects";
import Statistics from "./containers/Containers/Statistics";
import Professors from "./containers/Containers/Professors";
import Invitation from "./containers/Containers/Invitation";
import Privileges from "./containers/Containers/Privileges";
import Registrations from "./containers/Containers/Registration"
import GroupDetails from "./containers/Containers/GroupDetails"
import ResetPassword from "./containers/Containers/ResetPassword";
import SubjectDetails from "./containers/Containers/TopicDetails";
import StudentsPrints from "./containers/Containers/StudentsPrint";
import StudentDetails from "./containers/Containers/StudentDetails";
import PrivilegeDetails from "./containers/Containers/PrivilegeDetails";
import ProfessorDetails from "./containers/Containers/ProfessorDetails";
import StudentDocuments from "./containers/Containers/StudentsDocuments";
import ProfessorsSimulation from "./containers/Containers/ProfessorsSimulation";
import Survey from "./containers/Containers/Survey";
import Majors from "./containers/Containers/Majors";

export default function Routes({appProps}) {
    return (
        <Switch>
            <AppliedRoute path="/" exact component={Home} appProps={appProps}/>
            <AppliedRoute path="/login" exact component={Login} appProps={appProps}/>
            <AppliedRoute path="/signup" exact component={Signup} appProps={appProps}/>
            <AppliedRoute path="/majors" exact component={Majors} appProps={appProps}/>
            <AppliedRoute path="/reset" exact component={ResetPassword} appProps={appProps}/>
            <AppliedRoute path="/students" exact component={Students} appProps={appProps} />
            <AppliedRoute path="/students-light" exact component={StudentsLight} appProps={appProps} />
            <AppliedRoute path="/students-documents" exact component={StudentDocuments} appProps={appProps} />
            <AppliedRoute path="/students-prints" exact component={StudentsPrints} appProps={appProps} />
            <AppliedRoute path="/prospects" exact component={Prospects} appProps={appProps} />
            <AppliedRoute path="/invitation" exact component={Invitation} appProps={appProps} />
            <AppliedRoute path="/students/:id" exact component={StudentDetails} appProps={appProps} />
            <AppliedRoute path="/professors" exact component={Professors} appProps={appProps} />
            <AppliedRoute path="/professors/:id" exact component={ProfessorDetails} appProps={appProps} />
            <AppliedRoute path="/registrations" exact component={Registrations} appProps={appProps} />
            <AppliedRoute path="/professors-simulation" exact component={ProfessorsSimulation} appProps={appProps} />
            {/*<AppliedRoute path="/roles" exact component={Roles} appProps={appProps} />*/}
            <AppliedRoute path="/privileges" exact component={Privileges} appProps={appProps} />
            <AppliedRoute path="/privileges/:id" exact component={PrivilegeDetails} appProps={appProps} />
            <AppliedRoute path="/groups" exact component={Groups} appProps={appProps} />
            <AppliedRoute path="/topics" exact component={Topics} appProps={appProps} />
            <AppliedRoute path="/emails" exact component={Emails} appProps={appProps} />
            <AppliedRoute path="/statistics" exact component={Statistics} appProps={appProps} />
            <AppliedRoute path="/groups/:id" exact component={GroupDetails} appProps={appProps} />
            <AppliedRoute path="/topics/:id" exact component={SubjectDetails} appProps={appProps} />
            <AppliedRoute path="/survey" exact component={Survey} appProps={appProps} />
            <Route component={NotFound}/>
        </Switch>
    );
};
