import {API} from "aws-amplify";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import {useStyles} from "./TopicTableKardexStyles";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import {verifyPayments} from "../../CommonUtilities/UtilityFunctions";


export default function TopicTableKardex(props) {
    const classes = useStyles();
    const [grades, setGrades] = useState([]);
    const [topicHasBeenGraded, setTopicHasBeenGraded] = useState(true);

    useEffect(() => {
        setGrades(props.grades);
        let tmp = props.topicHasBeenGraded ? props.topicHasBeenGraded : false;
        setTopicHasBeenGraded(tmp);
    }, [props.grades, props.topicId, props.groupId, props.topicHasBeenGraded]);

    const enableGroupToProfessorsApi = () => {
        return API.put("misServiciosEscolares", `/topics/`, {
            body: {
                topicHasBeenGraded: false,
                topicId: `${props.topicId}#${props.groupId}`,
            }
        });
    };

    const enableGroupToProfessors = async () => {
        props.callback(true);
        try {
            await enableGroupToProfessorsApi();
            props.callback(false, false);
            setTopicHasBeenGraded(false);
        } catch (e) {
            props.callback(false, true);
        }
    };


    const handleChange = (nameValue, index) => ({target: {value}}) => {
        let updatedGrade, final;
        updatedGrade = {
            desempeno: parseFloat(grades[index].grade.desempeno || 0),
            actitud: parseFloat(grades[index].grade.actitud || 0),
            producto: parseFloat(grades[index].grade.producto || 0),
            conocimiento: parseFloat(grades[index].grade.conocimiento || 0),
        };
        updatedGrade[nameValue] = parseFloat(value);
        final = updatedGrade.desempeno + updatedGrade.actitud + updatedGrade.producto + updatedGrade.conocimiento;

        if (final > 10) {
            alert('La calificación final no puede ser mayor a 10');
            return;
        } else {
            setGrades([
                ...grades.slice(0, index),
                {
                    ...grades[index],
                    grade: {
                        ...grades[index].grade,
                        grade: `${final}`,
                        [nameValue]: value,
                    },
                }, ...grades.slice(index + 1)
            ]);
        }
    };

    const updateGradesApi = payload => {
        return API.put("misServiciosEscolares", "/grades", {
            body: payload
        });
    };

    function formatGradesForAPI() {
        let gradesPayload = {};
        grades.forEach(grade => {
            gradesPayload[grade.studentUuid] = {
                studentUuid: grade.studentUuid,
                studentId: grade.studentId,
                [props.topicId]: {
                    ...grade.grade,
                    topicId: props.topicId,
                },
            };
        });
        return gradesPayload;
    }

    const updateGrades = async () => {
        props.callback(true);
        const payload = {
            topicId: props.topicId,
            groupId: props.groupId,
            grades: formatGradesForAPI(),
        };
        try {
            await updateGradesApi(payload);
            props.callback(false, false);
        } catch (e) {
            props.callback(false, true);
        }
    };



    const columnTitles = ["Matrícula", "Nombre", "Desemp", "Product", "Actitud", "Conocim", "C. Final", "Status"]

    const TableHeader = () => {
        return (
            <TableHead>
                <TableRow className={classes.tableHeaders}>
                    {columnTitles.map(title => (<TableCell>{title}</TableCell>))}
                </TableRow>
            </TableHead>
        );
    };

    return (
        <div>
            <Button
                color='secondary'
                variant='contained'
                onClick={updateGrades}>
                Guardar cambios
            </Button>
            <Button
                style={{margin: '0px 15px'}}
                color='primary'
                variant='contained'
                onClick={enableGroupToProfessors}
                disabled={!topicHasBeenGraded}>
                Habilitar Grupo a Profesores
            </Button>
            <TableContainer component={Paper} className={classes.container}>
                <Table className={classes.table} aria-label="simple dense table">
                    <TableHeader/>
                    <TableBody>
                        {grades ? grades.map((row, index) => (
                            <TableRow key={row.studentUuid}
                                      style={{backgroundColor: verifyPayments(row.status) ? '#F08080' : 'clear'}}>
                                <TableCell component="th" scope="row">{row.studentId}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell><TextField
                                    value={row.grade.desempeno}
                                    type='number'
                                    step={0.1}
                                    // disabled={verifyPayments(row.status)}
                                    onChange={handleChange('desempeno', index)}
                                /></TableCell>
                                <TableCell><TextField
                                    value={row.type === 'extra' ? row.grade.productoExtra : row.grade.producto}
                                    type='number'
                                    // disabled={verifyPayments(row.status)}
                                    onChange={handleChange('producto', index)}
                                /></TableCell>
                                <TableCell><TextField
                                    value={row.grade.actitud}
                                    type='number'
                                    // disabled={verifyPayments(row.status)}
                                    onChange={handleChange('actitud', index)}
                                /></TableCell>
                                <TableCell><TextField
                                    value={row.type === 'extra' ? row.grade.conocimientoExtra : row.grade.conocimiento}
                                    type='number'
                                    // disabled={verifyPayments(row.status)}
                                    onChange={handleChange('conocimiento', index)}
                                /></TableCell>
                                <TableCell><TextField
                                    value={row.grade.grade}
                                    type='number'
                                    onChange={handleChange('grade', index)}
                                />
                                </TableCell>
                                <TableCell>
                                    {row.status}
                                </TableCell>
                            </TableRow>
                        )) : <></>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};