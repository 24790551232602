import 'date-fns';
import React from 'react';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 220,
    },
}));

export default function AddDateToTopic() {
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const classes = useStyles();

    const handleDateChange = (date) => {
        setSelectedDate(date);
        console.log(selectedDate);
    };

    return (
        < div >
            <form className={classes.container} noValidate>
                <TextField
                    id="datetime-local"
                    // label="Next appointment"
                    type="datetime-local"
                    defaultValue="2020-05-24T10:30"
                    onChange={() => handleDateChange("hello")}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </form>
        </div>
    );
};

