import React from "react";
import TextField from "@material-ui/core/TextField";
import {formatPhoneNumber, majors, statuses} from "../../CommonUtilities/UtilityFunctions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export function STUDENTS_DOCUMENTS_COLUMN_DEFINITION_PROPS(props) {
    return [
        {
            title: 'Nombre',
            field: 'name',
            render: rowData =>
                <Button color='secondary' onClick={() => props.history.push(`/students/${rowData.studentUuid}`)}
                        component={Link}>
                    {rowData.name}
                </Button>,
        },
        {
            title: 'Matrícula',
            field: 'studentId',
        },
        {
            title: 'Curp',
            field: 'curp',
            type: "boolean"
        },
        {
            title: 'Fotos Inf',
            field: 'fotosInf',
            type: "boolean"
        },
        {
            title: 'Fotos Cred',
            field: 'fotosCred',
            type: "boolean"
        },
        {
            title: 'Domicilio',
            field: 'domicilio',
            type: "boolean"
        },
        {
            title: 'Reglamento',
            field: 'reglamentoEscolar',
            type: "boolean"
        },
        {
            title: 'Bachillerato',
            field: 'bachillerato',
            type: "boolean"
        },
        {
            title: 'Certificado',
            field: 'certification',
            type: "boolean"
        },
        {
            title: 'Acta',
            field: 'nacimiento',
            type: "boolean"
        },
        {
            title: 'Elector',
            field: 'elector',
            type: "boolean"
        },
    ];
}
