import "./ModalNewGroup.css";
import {API} from "aws-amplify";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import React, {Fragment, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {calculateCurrentCuatrimestreYYYYMMDD, K_ALERT} from "../CommonUtilities/UtilityFunctions";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import {Tooltip} from "@material-ui/core";
import {UVT_STARTING_CYCLES} from "../../libs/Constants";

const useStyles = makeStyles((theme) => ({
    FormControl: {
        width: 550
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default function ModalNewGroup(props) {

    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const initialGroupState = {
        group: 'A',
        major: '',
        startingDate: calculateCurrentCuatrimestreYYYYMMDD(),
        startingCycle: "2022-01",
        plan: '2017',
        mode: 'ESCOLARIZADO'
    };
    const [group, setGroup] = useState(initialGroupState);

    const handleSubmit = async () => {
        props.callbackBackdrop(true);
        handleClose();
        try {
            await createGroupAPI(group);
            props.callbackSuccess();
            props.callbackBackdrop(false);
            props.callbackAlert({
                open: true,
                message: "El grupo ha sido creado en el sistema satisfactoriamente",
                severity: "success"
            });
        } catch (e) {
            console.log(e);
            props.callbackBackdrop(false);
            props.callbackAlert({
                open: true,
                message: K_ALERT.MESSAGE.ERROR,
                severity: K_ALERT.SEVERITY.ERROR
            });
        }
    };

    const handleClose = () => {
        setGroup(initialGroupState);
        setOpenModal(false);
    }

    const createGroupAPI = () => {
        return API.post("misServiciosEscolares", "/groups", {
            body: group
        });
    };

    return (
        <div className="ModalNewGroup">
            <Fragment>
                <Dialog open={openModal} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="modal-new-group">Crear Grupo</DialogTitle>
                    <DialogContent>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">
                                Carrera (Requerido)*
                            </InputLabel>
                            <Select
                                id="major-select-major"
                                value={group.major}
                                onChange={(event) => setGroup({...group, major: event.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"ENFERMERIA"}>Enfermería</MenuItem>
                                <MenuItem value={"PSICOPEDAGOGIA"}>Psicopedagogía</MenuItem>
                                <MenuItem value={"INDUSTRIAL"}>Industrial</MenuItem>
                                <MenuItem value={"ADMINISTRACION"}>Admin de Empresas</MenuItem>
                                <MenuItem value={"PETROLERA"}>Petrolera</MenuItem>
                                <MenuItem value={"DERECHO"}>Derecho</MenuItem>
                                <MenuItem value={"AMBIENTAL"}>Ambiental</MenuItem>
                                <MenuItem value={"MAESTRIA EDUCACION"}>Maestría en Educación</MenuItem>
                                <MenuItem value={"MAESTRIA PETROLERA"}>Maestría Petrolera</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="group">
                                Grupo (requerido)*
                            </InputLabel>
                            <Select
                                id="group-select-group"
                                value={group.group}
                                onChange={(event) => setGroup({...group, group: event.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"A"}>Grupo A</MenuItem>
                                <MenuItem value={"B"}>Grupo B</MenuItem>
                                <MenuItem value={"C"}>Grupo C</MenuItem>
                                <MenuItem value={"D"}>Grupo D</MenuItem>
                                <MenuItem value={"E"}>Grupo E</MenuItem>
                                <MenuItem value={"F"}>Grupo F</MenuItem>
                                <MenuItem value={"G"}>Grupo G</MenuItem>
                                <MenuItem value={"OPTATIVAS 1"}>OPTATIVAS 1</MenuItem>
                                <MenuItem value={"OPTATIVAS 2"}>OPTATIVAS 2</MenuItem>
                                <MenuItem value={"OPTATIVAS 3"}>OPTATIVAS 3</MenuItem>
                                <MenuItem value={"OPTATIVAS 4"}>OPTATIVAS 4</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="group">
                                Modalidad (requerido)*
                            </InputLabel>
                            <Select
                                id="group-select-mode"
                                value={group.mode}
                                onChange={(event) => setGroup({...group, group: event.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"ESCOLARIZADO"}>Escolarizado</MenuItem>
                                <MenuItem value={"SABADOS"}>Sábados</MenuItem>
                                <MenuItem value={"DOMINGOS"}>Domingos</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="plan">
                                Plan (requerido)*
                            </InputLabel>
                            <Select
                                id="plan-select-plan"
                                value={group.plan}
                                onChange={(event) => setGroup({...group, plan: event.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"2017"}>2017</MenuItem>
                                <MenuItem value={""}>2013</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="startingCycle">
                                Ciclo de Inicio (requerido)*
                            </InputLabel>
                            <Select
                                id="startingCycle"
                                value={group.startingCycle}
                                onChange={(event) => setGroup({...group, startingCycle: event.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                {UVT_STARTING_CYCLES.map(o => (
                                    <MenuItem value={o}>{o}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="secondary"
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            disabled={
                                group.major === "" ||
                                group.group === "" || group.startingCycle === ""}
                        >
                            Crear Grupo
                        </Button>
                    </DialogActions>
                </Dialog>
                <Fab color="primary" aria-label="add" className={classes.fab}>
                    <Tooltip title="Crear Grupo" aria-label="add">
                        <Fab color="primary" className={classes.fab}>
                            <AddIcon onClick={() => setOpenModal(!openModal)}/>
                        </Fab>
                    </Tooltip>
                </Fab>
            </Fragment>
        </div>
    );
};