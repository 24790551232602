import {API} from "aws-amplify";
import '../Stylesheets/Professors.css'
import "../Stylesheets/Students.css";
import MaterialTable from "material-table";
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {LOCALIZATION_GLOBAL_ES} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import TextField from "@material-ui/core/TextField";
import {EMAILS_COLUMN_DEFINITION} from "../../components/Material-TableCustomization/ColumnsDefinition/EmailsColuMnDefinition";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
    FormControl: {
        width: 550,
    },
    Email: {
        marginBottom: '15px'
    },
    InformationBox: {
        marginBottom: '100px'
    },
    Actions: {
        marginTop: '50px',
    },
    Lander: {
        textAlign: 'center'
    },
    Padding: {
        paddingTop: '15px',
        paddingBottom: '15px'
    }
}));

export default function Emails(props) {
    const classes = useStyles();
    const [emails, setEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState({subject: "", text: ""});
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});
    const [selectedRows, setSelectedRows] = useState([]);
    const [callbackStatus, setCallbackStatus] = useState({
        newProfessor: {},
        backdropOpen: false,
        alertStatus: {open: false, message: "", severity: ""}
    });

    const loadEmailsApi = () => {
        return API.get('misServiciosEscolares', "/emails");
    };

    const sendEmailsApi = (emailToSend) => {
        return API.post("misServiciosEscolares", "/emails", {
            body: emailToSend
        });
    }

    const sendEmail = async () => {
        if (!email.subject || !email.text || (emails.length === 0)) {
            alert("Necesitas escribir el asunto, texto y seleccionar destinatarios");
        } else {
            setIsLoading(true);
            const emailToSend = {
                to: selectedRows.map(row => row.email),
                subject: email.subject,
                text: email.text
            };
            try {
                await sendEmailsApi(emailToSend);
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const loadEmails = async () => {
        try {
            const response = await loadEmailsApi();
            setEmails(response);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handleChangeOnAlert = (shouldPresentAlert) => {
        setAlertStatus(shouldPresentAlert);
    }

    const destinatarios = [{value: 'PROFESORES', display: 'Profesores'}, {value: 'ADMIN', display: 'Administradores'}];

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadEmails().catch(error => {
            setIsLoading(false);
        });
    }, [props.isAuthenticated, props.match.params.id]);

    const renderEmails = () => {
        return (
            <div className="Emails">
                <div className={classes.Lander}>
                    <div>
                        <div className={classes.Padding}>
                            <TextField
                                fullWidth
                                autoFocus
                                margin="dense"
                                id="email-textfield-subject"
                                label="Asunto"
                                onChange={e => setEmail({...email, subject: e.target.value})}
                                placeholder="p.ej. UVT Cambios Administrativos"
                                className={classes.FormControl}
                            />
                        </div>
                        <div className={classes.Padding}>
                            <FormControl className={classes.formControl}>
                                <InputLabel>
                                    Selecciona Destinatarios
                                </InputLabel>
                                <Select
                                    // labelId={`formcontrol-select-status-${nameValue}`}
                                    // id={`formcontrol-select-status-${nameValue}`}
                                    // value={value || ''}
                                    // onChange={e => handleChange(nameValue, e)}
                                    required
                                    className={classes.FormControl}
                                >
                                    {destinatarios.map((status, i) => <MenuItem value={status.value}
                                                                           key={`f-s-mi-${i}`}>{status.display}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.Padding}>
                            <TextField
                                id="email-textfield-text"
                                label="Texto"
                                multiline
                                variant='outlined'
                                rows={4}
                                onChange={e => setEmail({...email, text: e.target.value})}
                                placeholder="p.ej. Se comunica que a partir de..."
                                className={classes.FormControl}
                            />
                        </div>
                        <div className={classes.Padding}>
                            <Button variant="contained" color="secondary" className={classes.FormControl}
                                    onClick={sendEmail}>
                                Mandar Email
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="Emails">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            <CustomizedBackdrop open={isLoading}/>
            {props.isAuthenticated ? renderEmails() : renderLander()}
        </div>
    );
};