import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddDateToTopic from "../../CommonUtilities/AddDateToTopic";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    actions: {
        marginBottom: "25px"
    }
});

export default function TopicTableSchedule(props) {
    const classes = useStyles();

    const handleEdit = () => {
        console.log("edit");
    }

    return (
        <div>
            <div className={classes.actions}>
                <Button variant="contained" color="primary">
                    Guardar cambios
                </Button>
            </div>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Acciones</TableCell>
                            <TableCell>Clase</TableCell>
                            <TableCell>Fecha y Hora</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <IconButton onClick={handleEdit}>
                                    <EditIcon color="primary" fontSize="small"/>
                                </IconButton>
                            </TableCell>
                            <TableCell>1</TableCell>
                            <TableCell><AddDateToTopic/></TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <IconButton onClick={handleEdit}>
                                    <EditIcon color="primary" fontSize="small"/>
                                </IconButton>
                            </TableCell>
                            <TableCell>2</TableCell>
                            <TableCell><AddDateToTopic/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <IconButton onClick={handleEdit}>
                                    <EditIcon color="primary" fontSize="small"/>
                                </IconButton>
                            </TableCell>
                            <TableCell>3</TableCell>
                            <TableCell><AddDateToTopic/></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};