import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import ButtonMaterialUIProgress from "../../CommonUtilities/ButtonMaterialUIProgress";
import TextField from "@material-ui/core/TextField";

export default function StudentDetailsNotes({studentUuid}) {
    const [studentNotes, setStudentNotes] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    function loadStudentNotesApi() {
        return API.get("misServiciosEscolares", `/notes/${studentUuid}`);
    }

    async function onLoad() {
        try {
            const {notes = ""} = await loadStudentNotesApi();
            setStudentNotes(notes);
        } catch (e) {
            console.log("Error loading student notes", e);
        }
    }

    useEffect(() => {
        onLoad();
    }, [studentUuid]);

    function handleTextFieldChange(e) {
        setStudentNotes(e.target.value);
    }

    function updateNotesApi() {
        return API.put("misServiciosEscolares", "/notes", {
            body: {
                studentUuid: studentUuid,
                notes: studentNotes,
            }
        });
    }

    async function updateNotes() {
        try {
            setIsLoading(true);
            await updateNotesApi();
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <ButtonMaterialUIProgress
                text="Guardar Información"
                color='secondary'
                variant='contained'
                onClick={updateNotes}
                loading={isLoading}
            />
            <TextField
                id="StudentDetailsNotesInput"
                label="Notas"
                multiline
                variant="outlined"
                rows={20}
                fullWidth={true}
                onChange={e => handleTextFieldChange(e)}
                placeholder="Notas"
                // className={classes.FormControl}
                value={studentNotes}
            />
        </>
    );
}
