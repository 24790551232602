import {API} from "aws-amplify";
import '../Stylesheets/Groups.css';
import "../Stylesheets/Students.css";
import MaterialTable from "material-table";
import React, {useState, useEffect} from "react";
import ModalNewGroup from "../../components/Modals/ModalNewGroup";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {
    LOCALIZATION,
} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {
    GROUPS_COLUMN_DEFINITION_FAST
} from "../../components/Material-TableCustomization/ColumnsDefinition/GroupsColumnDefinition";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import {K_ALERT, openAlert} from "../../components/CommonUtilities/UtilityFunctions";

export default function Groups(props) {
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const handleChangeOnAlert = (status) => {
        setAlertStatus(status);
    }

    const handleChangeOnBackdrop = (open) => {
        setIsLoading(open);
    }

    const onGroupCreate = () => {
        loadGroups();
    }

    const loadGroupsApi = () => {
        return API.get('misServiciosEscolares', "/groups?type=basic");
    }

    const updateGroupApi = (updatedGroup) => {
        return API.put("misServiciosEscolares", `/groups/${updatedGroup.groupId}`, {
            body: updatedGroup
        });
    }

    const deleteGroupApi = (group) => {
        return API.del("misServiciosEscolares", `/groups/${group.groupId}`);
    }

    const handleOnRowDelete = (newData, resolve, reject) => {
        deleteGroupApi(newData)
            .then(() => {
                setGroups(groups.filter(group => group.groupId !== newData.groupId));
            })
            .then(() => openAlert(setAlertStatus, K_ALERT.MESSAGE.GROUPS.GROUP_DELETE, K_ALERT.SEVERITY.SUCCESS))
            .catch(() => {
                openAlert(setAlertStatus);
                reject();
            })
            .finally(resolve());
    }

    const handleOnRowUpdate = (newData, oldData, resolve, reject) => {
        if (newData === oldData) {
            reject();
            return;
        }
        updateGroupApi(newData)
            .then(() => {
                loadGroups();
            })
            .then(() => openAlert(setAlertStatus, K_ALERT.MESSAGE.GROUPS.GROUP_UPDATE, K_ALERT.SEVERITY.SUCCESS))
            .catch(() => {
                reject()
                openAlert(setAlertStatus);
            })
            .finally(resolve());
    }

    const loadGroups = () => {
        setIsLoading(true);
        loadGroupsApi()
            .then((response) => {
                setGroups(response);
            })
            .catch(() => openAlert(setAlertStatus))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadGroups();
    }, [props.isAuthenticated]);

    function renderGroups() {
        return (
            <div className="Groups">

                <MaterialTable
                    columns={GROUPS_COLUMN_DEFINITION_FAST(props)}
                    data={groups}
                    title="Grupos UVT"
                    components={REMOVE_DROP_SHADOW}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('grupo', 'grupos')}
                    options={{
                        search: true,
                        pageSize: 10,
                        filtering: true
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            handleOnRowUpdate(newData, oldData, resolve, reject);
                        }),
                        onRowDelete: (newData) => new Promise((resolve, reject) => {
                            handleOnRowDelete(newData, resolve, reject);
                        })
                    }}
                />
                <ModalNewGroup callbackSuccess={onGroupCreate} callbackAlert={handleChangeOnAlert}
                               callbackBackdrop={handleChangeOnBackdrop}/>
            </div>
        );
    }

    return (
        <div className="Groups">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            <CustomizedBackdrop open={isLoading}/>
            {props.isAuthenticated ? renderGroups() : renderLander()}
        </div>
    );
};